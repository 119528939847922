import React, { useState } from 'react';
import { ChevronRight, Play, UserCircle } from 'lucide-react';
import './Section2.css'

const InvestmentSection = () => {
  const [showVideo, setShowVideo] = useState(false);

  const openVideo = () => setShowVideo(true);
  const closeVideo = () => setShowVideo(false);

  return (
    <div className="w-full bg-gray-100 py-16 md:py-24 pb-50 md:pb-[15rem]">
    <div className="max-w-[1400px] mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col xl:flex-row items-start justify-between">
        <div className="w-full xl:w-[60%] mb-12 xl:mb-0 pr-0 xl:pr-16">
            <div className="w-16 h-1 bg-red-600 mb-4"></div>
            <h2 className="text-gray-800 text-xl mb-2 font-semibold">OUR INTRODUCTIONS</h2>
            <h1 className="text-red-600 text-3xl md:text-4xl xl:text-5xl font-bold mb-8">
              INNOVATIVE INVESTING WITH ALBANIAN INVESTMENT CORPORATION
            </h1>
            <p className="text-gray-700 text-xl mb-8 leading-relaxed">
            Albania, with it's strategic location in Southeastern Europe, offers a dynamic and growing economy that is ripe for investment. Over the past decade, the country has undertaken significant reforms to create a business-friendly environment, making it an attractive destination for foreign investors.</p>
            <p className="text-gray-600 text-xl mb-10 leading-relaxed">
            From it's competitive tax policies to its expanding infrastructure and skilled workforce, the country itself presents a myriad of opportunities for businesses looking to establish a foothold in the region. </p>
            <p className="text-gray-600 text-xl mb-10 leading-relaxed">
            We hope that Albanian Investment Corporation will be an invaluable tool as you explore the opportunities within this promising market. Thank you for your interest in Albania, and we look forward to being a part of your successful business journey.</p>
            <button className="bg-red-600 hover:bg-red-700 transition-colors duration-300 text-white py-4 px-10 rounded-full text-xl font-bold flex items-center">
              VIEW PROJECTS
              <ChevronRight className="ml-3" size={24} />
            </button>
          </div>
          <div className="w-full xl:w-[35%] relative mt-12 xl:mt-0">
            <div className="relative md:flex md:flex-row xl:block">
            <div className="relative md:flex md:flex-row xl:block section-photo-1">
              <div className="relative w-full md:w-[65%] pb-[110%] rounded-3xl overflow-hidden shadow-2xl xl:mr-auto mb-24 md:mb-0">
                <img 
                  src="/tirana.jpg" 
                  alt="Tirana at sunset" 
                  className="absolute inset-0 w-full h-full object-cover"
                />
                <button 
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white bg-opacity-70 hover:bg-opacity-90 transition-all duration-300 rounded-full w-16 h-16 flex justify-center items-center cursor-pointer text-red-600" 
                  onClick={openVideo}
                >
                  <Play size={28} />
                </button>
                <div className="absolute top-4 right-4 bg-red-600 text-white py-2 px-4 rounded-xl shadow-lg">
                  <span className="block text-xs font-medium">SINCE</span>
                  <span className="text-2xl font-extrabold">2019</span>
                </div>
              </div>
              <div className="
                section-photo-2
                  relative w-[100%] h-[40rem] mx-auto
                  sm:w-[100%] sm:h-[40rem] sm:relative
                  md:absolute md:bottom-[-230px] md:right-0 md:w-[50%] md:h-[36rem]
                  lg:bottom-[-290px] lg:right-0 lg:w-[40%] lg:h-[36rem]
                  xl:-bottom-70 xl:-right-20 xl:w-[70%] xl:h-[32rem]
                  bg-gray-200 rounded-3xl overflow-hidden shadow-xl mt-8 md:mt-0
                ">
                  <img 
                    src="/lady.jpg" 
                    alt="Executive Director" 
                    className="
                      absolute top-0 left-0 
                      w-[260%] h-full object-cover object-[10%_30%]
                      md:w-[260%] md:object-[8%_30%]
                      lg:w-[300%] lg:object-[2%_50%]
                      xl:w-[250%] xl:object-[6%_40%]
                    "
                  />

                <div className="absolute bottom-0 left-0 right-0 bg-white bg-opacity-90 p-4 text-center">
                  <h3 className="text-2xl md:text-3xl text-red-600 font-['Corinthiago',cursive] mb-3 font-bold">
                    Elina Kokona
                  </h3>
                  <p className="text-gray-600 text-sm">- Executive Director -</p>
                </div>
                <div className="absolute bottom-4 right-4 bg-red-600 rounded-full p-1">
                  <UserCircle size={24} className="text-white" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      {showVideo && (
  <div className="fixed inset-0 bg-black bg-opacity-90 flex justify-center items-center z-50" onClick={closeVideo}>
    <div className="relative w-[80%] h-[80%] mx-auto">
      <iframe 
        src="/video.mp4" 
        frameBorder="0"
        allowFullScreen
        className="w-full h-full"
      ></iframe>
    </div>
  </div>
)}
    </div>
  );
};

export default InvestmentSection;