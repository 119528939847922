import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Clock, Mail, MapPin, Linkedin, Instagram, Facebook, Search, ChevronDown, Network, Folder, Globe, PieChart, RefreshCw, Settings, Twitter } from 'lucide-react';

const Header = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1024);
  const [openMobileDropdown, setOpenMobileDropdown] = useState(null);

  const menuItems = [
    {
      title: 'ABOUT US',
      submenu: [
        { title: 'Our Vision & Mission', link: '/about-us/vision-and-mission' },
        { title: 'Why Choose AIC', link: '/about-us/why-choose-aic' },
        { title: 'By Laws and Regulations', link: '/about-us/law-and-regulations' },
      ],
    },
    { title: 'NEWS', link: '/news' },
    { title: 'PROJECTS', link: '/projects' },
    {
      title: 'OPEN CALLS',
      submenu: [
        { title: 'Ongoing', link: '/open-calls/ongoing' },
        { title: 'Closed Calls', link: '/open-calls/closed' },
      ],
    },
    {
      title: 'TEAM',
      submenu: [
        { title: 'Institutional Structure', link: '/team/institutional-structure' },
        { title: 'Meet the Staff', link: '/team/meet-the-staff' },
        { title: 'Join Us', link: '/team/join-us' },
      ],
    },
    {
      title: 'NETWORKING',
      submenu: [
        { title: 'Relationship with Line Ministers', link: '/networking/relationship-with-line-ministers' },
        { title: 'Relationship with AIDA', link: '/networking/relationship-with-aida' },
        { title: 'Relationship with ADF', link: '/networking/relationship-with-adf' },
      ],
    },
    { title: 'CONTACT', link: '/contact' },
  ];

  const features = [
    { Icon: Network, text: 'EXPERTISE AND EXPERIENCE' },
    { Icon: Folder, text: 'DIVERSE INVESTMENT PORTFOLIO' },
    { Icon: Globe, text: 'INNOVATIVE FINANCIAL SOLUTIONS' },
    { Icon: PieChart, text: 'TRANSPARENCY & ACCOUNTABILITY' },
    { Icon: RefreshCw, text: 'STRATEGIC PARTNERSHIPS' },
    { Icon: Settings, text: 'SUSTAINABLE DEVELOPMENT' },
  ];

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    document.body.style.overflow = isDropdownOpen ? 'auto' : 'hidden';
  };

  const toggleMobileDropdown = (index, e) => {
    e.stopPropagation();
    setOpenMobileDropdown(openMobileDropdown === index ? null : index);
  };

  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 1024;
      setIsMobile(isMobileView);

      if (!isMobileView) {
        setIsDropdownOpen(false);
        document.body.style.overflow = 'auto';
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.body.style.overflow = 'auto';
    };
  }, []);

  return (
    <div className="relative w-full text-white font-rubik min-h-[600px] h-[calc(100vh-60px)] md:min-h-[600px] md:h-screen">
      <div className="absolute inset-0">
        {/* Background Image */}
        <div className="absolute inset-0 z-10">
          <div className="absolute inset-0 bg-black/50"></div>
          <img src="/Image2.jpg" alt="Image 2" className="w-full h-full object-cover" />
        </div>

        {/* Content */}
        <div className="relative z-10 flex flex-col h-full">
          <div className="flex-none">
            {/* Top Info Bar */}
            <div className="hidden lg:flex justify-end items-center py-2 px-5 text-sm">
              <div className="flex gap-5">
                <span className="flex items-center"><Clock className="w-4 h-4 mr-1" /> Mon - Fri : 08:00 - 17:00</span>
                <span className="flex items-center"><Mail className="w-4 h-4 mr-1" /> info@corporation.al</span>
                <span className="flex items-center"><MapPin className="w-4 h-4 mr-1" /> St. Ibrahim Rugova 5, Sky Tower 7/1, Tirana, Albania</span>
              </div>
              <button className="bg-red-600 text-white px-4 py-1 rounded-lg ml-5">INVEST NOW!</button>
            </div>

            {/* Main Menu */}
            <div className="flex justify-between items-center px-5 relative">
              <div className="py-2">
                <Link to="/">
                  <img src="./AIClogo.png" alt="Albanian Investment Corporation Logo" className="h-20 w-60 object-contain" />
                </Link>
              </div>

              {isMobile && (
                <button 
                  className={`lg:hidden flex flex-col justify-center items-center w-8 h-6 space-y-1.5 ${isDropdownOpen ? 'open' : ''}`} 
                  onClick={toggleDropdown}
                >
                  <span className="w-full h-0.5 bg-white transform transition duration-300 ease-in-out"></span>
                  <span className="w-full h-0.5 bg-white transform transition duration-300 ease-in-out"></span>
                  <span className="w-full h-0.5 bg-white transform transition duration-300 ease-in-out"></span>
                </button>
              )}

              <nav className={`hidden lg:flex ${isDropdownOpen ? 'active' : ''}`}>
                <ul className="flex">
                  {menuItems.map((item, index) => (
                    <li key={index} className="relative group">
                      {item.link ? (
                        <Link to={item.link} className="px-4 py-2 block hover:bg-white/10 transition duration-300">
                          {item.title}
                        </Link>
                      ) : (
                        <span className="px-4 py-2 block cursor-default hover:bg-white/10 transition duration-300">
                          {item.title}
                        </span>
                      )}
                      {item.submenu && (
                        <div className="absolute top-full left-1/2 transform -translate-x-1/2 bg-red-600 rounded-md hidden group-hover:block w-56 shadow-lg">
                          <ul className="py-2">
                            {item.submenu.map((subItem, subIndex) => (
                              <li key={subIndex} className="px-2">
                                <Link 
                                  to={subItem.link} 
                                  className="block px-2 py-2 text-left hover:bg-white hover:text-red-600 transition duration-300 rounded"
                                >
                                  {subItem.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </li>
                  ))}
                </ul>
              </nav>

              {/* Social Media Icons - Always visible on desktop */}
              <div className={`hidden lg:flex gap-2 mr-24`}>
                <a href="https://www.linkedin.com/company/the-albanian-investment-corporation/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><Linkedin className="w-4 h-4" /></a>
                <a href="https://www.instagram.com/albanianinvestmentcorporation/" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><Instagram className="w-4 h-4" /></a>
                <a href="https://www.facebook.com/AlbanianInvestmentCorporation" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><Facebook className="w-4 h-4" /></a>
                <a href="https://x.com/albania_aic/" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><Twitter className="w-4 h-4" /></a>
              </div>
            </div>
            <div className="border-b border-white mx-5"></div>
          </div>

          {/* Slider Content */}
          <div className="flex-grow flex items-center -mt-60">
            <div className="w-[80%] md:w-[70%] mx-auto text-left">
              <p className="text-white font-semibold mb-2 text-sm sm:text-base lg:text-xl relative">
                EMPOWERING INVESTORS, DRIVING ECONOMIC GROWTH
                <span className="absolute bottom-[-15px] left-[0%] w-20 h-2.5 bg-red-600"></span>
              </p>
              <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold leading-tight mt-8">
                YOUR GATEWAY TO DYNAMIC<br />INVESTMENT OPPORTUNITIES<br />IN ALBANIA
              </h1>
            </div>
          </div>

          {/* Red Boxes */}
          <div className="flex-none">
            <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[100%] lg:w-[80%] md:w-[90%] bg-red-600 text-white flex flex-wrap justify-between items-stretch rounded-t-3xl z-20 overflow-hidden">
              {features.map(({ Icon, text }, index) => (
                <div key={index} className="flex flex-col items-center justify-center text-center px-2 w-1/2 sm:w-1/3 lg:w-1/6 py-4 md:py-6 lg:py-8 relative">
                  <Icon className="w-5 h-5 sm:w-10 sm:h-10 lg:w-12 lg:h-12 mb-2 sm:mb-3" />
                  <span className="text-xs sm:text-sm uppercase max-w-[100px] sm:max-w-[120px] lg:max-w-[140px] leading-tight">
                    {text}
                  </span>
                  {(index !== features.length - 1) && (
                    <>
                      <div className="absolute right-0 top-[15%] h-[70%] w-0.5 bg-white/30 hidden sm:block"></div>
                      <div className="absolute bottom-0 left-[15%] w-[70%] h-0.5 bg-white/30 sm:hidden"></div>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {isMobile && (
        <div className={`fixed inset-0 bg-white z-50 transform ${isDropdownOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out`}>
          <button className="absolute top-5 right-5 text-red-600 text-3xl" onClick={toggleDropdown}>X</button>
          <div className="pt-20 px-5">
            <nav>
              <ul>
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <div className="flex justify-between items-center">
                      {item.link ? (
                        <Link to={item.link} className="text-red-600 font-bold py-2 block" onClick={toggleDropdown}>{item.title}</Link>
                      ) : (
                        <span className="text-red-600 font-bold py-2 block">{item.title}</span>
                      )}
                      {item.submenu && (
                        <button
                          className="p-2"
                          onClick={(e) => toggleMobileDropdown(index, e)}
                        >
                          <ChevronDown
                            className={`w-6 h-6 text-red-600 transform transition-transform duration-300 ${openMobileDropdown === index ? 'rotate-180' : ''}`}
                          />
                        </button>
                      )}
                    </div>
                    {item.submenu && (
                      <div className={`pl-5 ${openMobileDropdown === index ? 'block' : 'hidden'}`}>
                        <ul>
                          {item.submenu.map((subItem, subIndex) => (
                            <li key={subIndex}>
                              <Link to={subItem.link} onClick={toggleDropdown} className="text-red-600 font-bold py-2 block">{subItem.title}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
            <div className="border-t border-red-600 my-5"></div>
            <div className="relative">
              <input type="text" placeholder="Search..." className="w-full p-2 border-2 border-red-600 rounded-md" />
              <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-600 w-5 h-5" />
            </div>
            <div className="flex gap-5 mt-5">
              <a href="https://www.facebook.com/share/p/FT9ppzzRj1hs6Km3/?mibextid=WC7FNe" aria-label="Facebook" target="_blank" rel="noopener noreferrer"><Facebook className="w-6 h-6 text-red-600" /></a>
              <a href="https://www.instagram.com/p/C_3UCS3tvyX/?igsh=MWY2MGxkdGl4b2NsMg==" aria-label="Instagram" target="_blank" rel="noopener noreferrer"><Instagram className="w-6 h-6 text-red-600" /></a><a href="https://www.linkedin.com/posts/the-albanian-investment-corporation_rom%C3%AB-itali-n%C3%ABnshkruam-sot-marr%C3%ABveshjen-activity-7240402301532688384-Posp?utm_source=share&utm_medium=member_ios" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer"><Linkedin className="w-6 h-6 text-red-600" /></a>
              <a href="https://x.com/albania_aic/status/1834634831642206698?s=46" aria-label="Twitter" target="_blank" rel="noopener noreferrer"><Twitter className="w-6 h-6 text-red-600" /></a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;