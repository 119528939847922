import React, { useState, useEffect } from 'react';
import sanityClient from '../../sanity';
import './NewsPage.css';

const NewsPage = () => {
  const [newsPosts, setNewsPosts] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "newsPost"]{
          title,
          slug,
          mainImage{
            asset->{
              _id,
              url
            },
            alt
          },
          body,
          category,
          "excerpt": pt::text(body)[0...200]
        }`
      )
      .then((data) => {
        const categorizedPosts = data.reduce((acc, post) => {
          if (!acc[post.category]) {
            acc[post.category] = [];
          }
          acc[post.category].push(post);
          return acc;
        }, {});
        setNewsPosts(categorizedPosts);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setError("Failed to load news posts.");
        setLoading(false);
      });
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const categories = ['news', 'events', 'careers', 'notices'];

  return (
    <div className="newspage">
      {categories.map((category) => (
        <NewsCategory 
          key={category} 
          category={category} 
          posts={newsPosts[category] || []}
          isMobile={isMobile}
        />
      ))}
    </div>
  );
};

const NewsCategory = ({ category, posts, isMobile }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const nextSlide = () => {
    if (isAnimating || posts.length <= 1) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) % posts.length
    );
    setTimeout(() => setIsAnimating(false), 500);
  };

  const prevSlide = () => {
    if (isAnimating || posts.length <= 1) return;
    setIsAnimating(true);
    setCurrentIndex((prevIndex) => 
      (prevIndex - 1 + posts.length) % posts.length
    );
    setTimeout(() => setIsAnimating(false), 500);
  };

  const renderNewsItem = (post) => (
    <div key={post.slug.current} className="newspage-item">
      <img
        src={post.mainImage?.asset?.url}
        alt={post.mainImage?.alt || 'News Image'}
        className="newspage-image"
      />
      <div className="newspage-content">
        <h3 className="newspage-item-title">{post.title}</h3>
        <p className="newspage-excerpt">{post.excerpt}...</p>
        <a href={`/news/${post.slug.current}`} className="newspage-read-more">
          Read More
        </a>
      </div>
    </div>
  );

  if (isMobile) {
    return (
      <div className="newspage-category newspage-category-mobile">
        <h2>{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
        <div className="newspage-mobile-container">
          {posts.length > 0 ? (
            <>
              {renderNewsItem(posts[currentIndex])}
              {posts.length > 1 && (
                <div className="newspage-mobile-nav">
                  <button className="newspage-nav newspage-prev" onClick={prevSlide} disabled={isAnimating}>❮</button>
                  <button className="newspage-nav newspage-next" onClick={nextSlide} disabled={isAnimating}>❯</button>
                </div>
              )}
            </>
          ) : (
            <div className="newspage-empty-message">
              Currently, there's no content available for {category}.
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="newspage-category">
      <h2>{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
      <div className="newspage-grid-container">
        {posts.length > 0 ? (
          <div className="newspage-grid">
            {posts.slice(currentIndex, currentIndex + 3).map(renderNewsItem)}
          </div>
        ) : (
          <div className="newspage-empty-message">
            Currently, there's no content available for {category}.
          </div>
        )}
      </div>
      {posts.length > 3 && (
        <div className="newspage-desktop-nav">
          <button className="newspage-nav newspage-prev" onClick={prevSlide} disabled={isAnimating}>❮</button>
          <button className="newspage-nav newspage-next" onClick={nextSlide} disabled={isAnimating}>❯</button>
        </div>
      )}
    </div>
  );
};

export default NewsPage;